import React from 'react'
import Content from '../components/Content'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Hero from '../components/Hero'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'

import hold_it from '../assets/images/hold_it.png';

function UnderConstructionPage(props) {
    return (
        <div>
            <Hero title={props.title}></Hero>
            <Content>
                <Container fluid={true}>
                    <Row className=" justify-content-between p-1">
                        <Col><img src={hold_it} alt="Howdy"></img></Col>
                        <Col classname="p-0 d-flex justify-content-end" xs={10}>
                            <p>
                                Hello there, wanderer! It would seem you've found something that is still being built!
                        </p>
                            <p>
                                Come take a look after it's done. :)
                        </p>
                        </Col>
                    </Row>
                </Container>
            </Content>
        </div>
    );
}

export default UnderConstructionPage