import React from 'react'
import Content from '../components/Content'
import Hero from '../components/Hero'


class StoryHubPage extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        isActive: false
    }

    handleShow = () => {
        this.setState({
            isActive: true
        })
    }

    handleHide = () => {
        this.setState({
            isActive: false
        })
    }

    render() {
        return (
            <div>
                <Hero title={this.props.title} />
                <Content>
                    <h2>What this page is about</h2>
                    <p>
                    This page is a hub for the short stories and documents I’ve made for my personal projects.
                    <br></br>
                    I plan on filling this document with different kinds of stories and writing styles.
                    </p>

                    <h3>1. Old Friend, New Quest</h3>
                    <p>
                        A short story about the protagonist of an as of yet unreleased shmup game that takes place in a fantasy world.
                        <br></br>
                        Bored from her everyday missions, she goes to a remote town to meet a friend she hasn't talked to in a while.
                    </p>

                    <p>Contains:</p>
                    <div className='item_list'>
                        <ul>
                            <p>▫️ Descriptive Writing</p>
                            <p>▫️ Introductory Writing</p>
                            <p>▫️ Character Dialog</p>
                        </ul>
                    </div>
                    <p>
                        [English: <a href='https://docs.google.com/document/d/1N9l5nZBQ6fzn4Q2P9DAuy6GGl5T-nImdsjhuayh_C6s/edit?usp=sharing' target="_blank">Link</a>]
                        <br></br>
                        [Portuguese: <a href='https://docs.google.com/document/d/1qT81gawN-2ARZzsBmcVmQxCSgUC1JClfz0lLqxaXa5c/edit?usp=sharing' target="_blank">Link</a>]
                    </p>
                    

                    <p></p>
                </Content>
            </div>
        );
    }

}



export default StoryHubPage