import React from 'react'
import Carrousel from '../components/Carrousel';

import Hero from '../components/Hero'

function HomePage(props){
    return(
        <div>
            <Hero title={props.title} subtitle={props.subtitle} text={props.text} text2={props.text2}/>
            <Carrousel />
        </div>
        
    );
}

export default HomePage