import React from 'react'
import Content from '../components/Content'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Hero from '../components/Hero'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'

import unity_frame from '../assets/images/frames/unity_frame.png';
import vs_frame from '../assets/images/frames/vs_frame.png';
import aseprite_frame from '../assets/images/frames/aseprite_frame.png';
import cs_frame from '../assets/images/frames/c#_frame.png';
import github_frame from '../assets/images/frames/github_frame.png';
import csp_frame from '../assets/images/frames/clipstudiopaint_frame.png';

class GamesHubPage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
    
        return (
            <div>
                <Hero title={this.props.title} />
                <Content>
                <h2>What this page is about</h2>
                    <p>
                    This page is a hub for personal game projects that I'm making or have made so far.
                    </p>

                    <h3>Project FOXIE</h3>
                    <p> A small game made over in less than three weeks for the birthday of a friend.</p>
                    <p> With the modelling skills of "<a href='https://x.com/shadicscarlet' target="_blank">Shadic Scarlet</a>", we created a 3D Top-Down RPG adventure game will platforming elements.</p>
                    <p> The game consists of Foxie helping her friends, finding solutions and items for their problems while exploring a carefully created world.</p>
                    <p> As the game's final stage, we made a basic and short hack-and-slash level.</p>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/6feEf0JrgBY?si=MqRrMpxgyz-IrVcm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    <p></p>
                    <div className='item_list'>
                        <ul>
                        <p>▫️ Project Details: </p>
                            <img src={unity_frame} alt={"Unity Engine"}/>
                            &nbsp;
                            <img src={vs_frame} alt={"Visual Studio"}/>
                            &nbsp;
                            <img src={cs_frame} alt={"C-Sharp"}/>
                            &nbsp;
                            <img src={github_frame} alt={"Github"}/>

                            

                            <p>▫️ Project Repository: Not Available</p>
                            <p>▫️ Features and original work: </p>
                            <ul>
                                <p>• Compatibility with both Gamepad and Keyboard</p>
                                <p>• Player Movement</p>
                                <p>• Platforming Design</p>
                                <p>• Item Implementation via use of Scriptable Objects</p>
                                <p>• Projectile implementation and damage calculation</p>
                                <p>• Basic Inventory System and Management</p>
                                <p>• Additive Scene Loading & Portal Usage (move from one location to another)</p>
                                <p>• Physics Tuning</p>
                                <p>• Dialogue Management</p>
                                <p>• Moving Platforming Code</p>
                                <p>• Music Loop System</p>
                                <p>• Basic Hack-and-Slash Movement and Attacks</p>
                                <p>• Cinematic Cutscenes Management</p>
                            </ul>
                        </ul>
                    </div>

                    <h3>RPG Prototype</h3>
                    <p>
                        A small prototype for an RPG system developed overtime.
                    </p>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/UjfzUg7neEE?si=T54rgL_e93ZS7ZV1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    <p></p>
                    <div className='item_list'>
                        <ul>
                        <p>▫️ Project Details: </p>
                            <img src={unity_frame} alt={"Unity Engine"}/>
                            &nbsp;
                            <img src={vs_frame} alt={"Visual Studio"}/>
                            &nbsp;
                            <img src={cs_frame} alt={"C-Sharp"}/>
                            &nbsp;
                            <img src={github_frame} alt={"Github"}/>
                            &nbsp;
                            <img src={aseprite_frame} alt={"Aseprite"}/>
                            

                            <p>▫️ Project Repository: Not Available</p>
                            <p>▫️ Features and original work: </p>
                            <ul>
                                <p>• Compatibility with both Gamepad and Keyboard</p>
                                <p>• Player and NPC Movement</p>
                                <p>• Dialog System</p>
                                <p>• Random Encounters</p>
                                <p>• Character Implementation via use of Scriptable Objects</p>
                                <p>• Attack implementation, damage calculation and status effects</p>
                                <p>• Complete turn-based Battle System (action and move selection & HUD implementation)</p>
                                <p>• Level-up system</p>
                                <p>• Basic Party System</p>
                                <p>• Additive Scene Loading & Portal Usage (move from one location to another)</p>
                                <p>• Save/Load System</p>
                                <p>• Pause Menu</p>
                                <p>• Party Menu</p>
                                <p>• Some Overworld Sprites</p>
                            </ul>
                        </ul>
                    </div>

                    <h3>Unity Shmup</h3>
                    <p>
                        A small shoot'em up (shmup, for short) level developed while studying the shooters genre.
                        <br></br>
                    </p>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/8fazzdaehcg?si=zmc2wu_g3mS1VDhI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    <p></p>
                    <div className='item_list'>
                        <ul>
                            <p>▫️ Project Details: </p>
                            <img src={unity_frame} alt={"Unity Engine"}/>
                            &nbsp;
                            <img src={vs_frame} alt={"Visual Studio"}/>
                            &nbsp;
                            <img src={cs_frame} alt={"C-Sharp"}/>
                            &nbsp;
                            <img src={github_frame} alt={"Github"}/>
                            &nbsp;
                            <img src={aseprite_frame} alt={"Aseprite"}/>
                            &nbsp;
                            <img src={csp_frame} alt={"Clip Studio Paint"}/>
                            
                            <p>▫️ Project Repository: <a href='https://github.com/TeamAzureOmen/BulletHell' target="_blank">Link</a></p>
                            <p>▫️ Features and original work: </p>
                            <ul>
                                <p>• Compatibility with both Gamepad and Keyboard</p>
                                <p>• Projectile Patterns</p>
                                <p>• Gameplay Controls</p>
                                <p>• Enemy and Projectile Instantiation</p>
                                <p>• Damage Calculation</p>
                                <p>• Graze System (The act of the player avoiding projectiles by few pixels)</p>
                                <p>• Cutscenes and Dialog System</p>
                                <p>• Life management, Score calculation and Projectile Power increase</p>
                                <p>• Card count increase (abandoned feature)</p>
                                <p>• Character Design</p>
                            </ul>
                        </ul>
                    </div>

                    <p></p>
                </Content>
            </div>
        );
    }
}


export default GamesHubPage
