import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

import useDarkMode from 'use-dark-mode';
/* import Toggle from './Toggle';
 */
import Footer from './components/Footer'
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import GamesHubPage from './pages/GamesHubPage';
import BehindTheDevPage from './pages/BehindTheDevPage';
import UnderConstructionPage from './pages/UnderConstructionPage';
import StoryHubPage from './pages/StoryHubPage';
import ArtPage from './pages/ArtPage';

import dg from './assets/images/dg.png';

class App extends React.Component {

  constructor(props) {
    super(props);

    const dg_style = {
      width: 10,
      height: 10
    }

    this.state = {
      title: 'AzureOmen',
      headerLinks: [
        {
          title: 'Home', path: '/',
          title: 'About', path: '/about',
          title: 'Contact', path: '/contact'
        }
      ],
      home: {
        title: '',
        subtitle: 'Hello and welcome!',
        text: '"Team Azure Omen" is a one-developer team that aims to create enjoyable games and systems.',
        text2: "If you\'re curious on what I do, please have a look around!"
      },
      about: {
        title: 'About me'
      },
      contact: {
        title: 'Let\'s talk'
      },
      games_page: {
        title: 'Games and Projects'
      },
      behindthedev: {
        title: 'Behind The Dev'
      },
      under_construction: {
        title: 'Work in progress!'
      },
      story_hub:{
        title: 'Story Hub'
      },
      art_page:{
        title: 'Art Page'
      }
    }

  }

  render() {
    return (
      <Router>
        <Container className="p-0" fluid={true} >
          {/*Whole Navbar */}
          <Navbar className="border-bottom" bg="transparent" expand="lg">
            <Navbar.Brand><img src={dg} alt="" style={{width: 237, height: 63}}></img></Navbar.Brand>
            
            {/*Toggle with dropdown elements*/}
            <Navbar.Toggle className="border-0" aria-controls="navbar-toggle" />
            
            <Navbar.Collapse id="navbar-toggle">
              
              <Nav className="ml-auto">
              
                <Link className="nav-link" to="/"><p>Home</p></Link>
                <Link className="nav-link" to="/about"> <p>About</p> </Link>
                <Link className="nav-link" to="/contact"> <p>Contact</p></Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>

          {/* Renders different components for each available path */}
          <Route path="/" exact render={() => <HomePage title={this.state.home.title} subtitle={this.state.home.subtitle} text={this.state.home.text} text2={this.state.home.text2} />} />
          <Route path="/about" exact render={() => <AboutPage title={this.state.about.title} />} />
          <Route path="/contact" exact render={() => <ContactPage title={this.state.contact.title} />} />
          <Route path="/behindthedev" exact render={() => <BehindTheDevPage title={this.state.behindthedev.title} />} />
          <Route path="/games_page" exact render={() => <GamesHubPage title={this.state.games_page.title} />} />
          <Route path="/under_construction" exact render={() => <UnderConstructionPage title={this.state.under_construction.title} />} />
          <Route path="/story_hub" exact render={() => <StoryHubPage title={this.state.story_hub.title} />} />
          <Route path="/art_page" exact render={() => <ArtPage title={this.state.art_page.title} />} />
          <Footer />

        </Container>
      </Router>
    );
  }


}

export default App;
