import React from 'react'
import Content from '../components/Content'
import Hero from '../components/Hero'


class BehindTheDevPage extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        isActive: false
    }

    handleShow = () => {
        this.setState({
            isActive: true
        })
    }

    handleHide = () => {
        this.setState({
            isActive: false
        })
    }

    render() {
        return (
            <div>
                <Hero title={this.props.title} />
                <Content>
                    <h2>Quick background</h2>
                    <p>I took an interest in coding as early as I was in second grade. This interest came from watching and playing video games like the Pokemon or Zelda series with my friends.</p>
                    <p>This interest expanded as the years went by, and it influenced me to try other activities like swimming, writting, catching bugs and sightseeing.
                        The gamification was (and still is) there at all times, which became my source of motivation.
                    </p>
                    <p>After finishing my college degree in Software Engineering at Setúbal's Polytechnic Institute, I learned the means to make quality apps and develop the things I want to make.</p>
                    <p>While I'm well-versed in developing a wide range of software applications,
                        I want to explore my love for video game development further and share the enjoyment I get from developing the games I want to make.
                    </p>
                </Content>
            </div>
        );
    }

}



export default BehindTheDevPage