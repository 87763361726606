import React from 'react'
import Content from '../components/Content'
import Hero from '../components/Hero'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'

import unity_frame from '../assets/images/frames/unity_frame.png';
import vs_frame from '../assets/images/frames/vs_frame.png';
import vscode_frame from '../assets/images/frames/vscode_frame.png';
import aseprite_frame from '../assets/images/frames/aseprite_frame.png';
import cs_frame from '../assets/images/frames/c#_frame.png';
import github_frame from '../assets/images/frames/github_frame.png';
import java_frame from '../assets/images/frames/java_frame.png';
import javascript_frame from '../assets/images/frames/javascript_frame.png';
import react_frame from '../assets/images/frames/react_frame.png';
import sql_frame from '../assets/images/frames/sql_frame.png';
import intellij_frame from '../assets/images/frames/intellij_frame.png';
import kotlin_frame from '../assets/images/frames/kotlin_frame.png';
import csp_frame from '../assets/images/frames/clipstudiopaint_frame.png';


function AboutPage(props){
    return(
        <div>
            <Hero title = {props.title}></Hero>
            <Content>

                <p>Hello! You've stumbled on the page of Team Azure Omen.</p>
                
                <p>My goal is to one day becoming a game programmer and contribute to enoyable games.<br></br>
                    I plan on updating this website with my creations as I keep learning.</p>
                
                <p>I specialize in game programming mostly, but I express interest in game design, character design and storytelling (visual and narrative) as well.</p>
                <p>Currently, I am unemployed and taking the time to increase the quality of my skills as I find a new challenge.</p>

                <br></br>
                <br></br>

                <h3>Editors/Engines:</h3>
                <img src={unity_frame} alt={"Unity Engine"}/>
                &nbsp;
                <img src={vs_frame} alt={"Visual Studio"}/>
                &nbsp;
                <img src={vscode_frame} alt={"Visual Studio Code"}/>
                &nbsp;
                <img src={intellij_frame} alt={"IntelliJ Idea"}/>
                
                <h3>Languages/Technologies:</h3>
                <img src={javascript_frame} alt={"Javascript"}/>
                &nbsp;
                <img src={java_frame} alt={"Java"}/>
                &nbsp;
                <img src={cs_frame} alt={"C-Sharp"}/>
                &nbsp;
                <img src={sql_frame} alt={"SQL"}/>
                &nbsp;
                <img src={react_frame} alt={"React"}/>
                &nbsp;
                <img src={kotlin_frame} alt={"Kotlin"}/>

                <h3>Version Control:</h3>
                <img src={github_frame} alt={"Github"}/>

                <h3>Additional Skills:</h3>
                <img src={aseprite_frame} alt={"Aseprite"}/>
                &nbsp;
                <img src={csp_frame} alt={"Clip Studio Paint"}/>

            </Content>
        </div>
    );
}

export default AboutPage