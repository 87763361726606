import React from 'react';

import { useSpring, animated } from 'react-spring'
import Nav from 'react-bootstrap/Nav'

import { BrowserRouter as Router, Route, Link } from 'react-router-dom'




function CardInfo(props) {
    const style = useSpring({ opacity: 1, from: { opacity: 0 } });

    return (
        <animated.div className="" style={style}>
            <p className="g-card-title">{props.title}</p>
            {/*<p className="g-card-subtitle">{props.subtitle}</p>*/}
            {/* VVV Links the sites VVV */}
  {/*           <a href={props.link} rel="noopener noreferrer"><p>View</p></a> */}
            <Link className="nav-link" to={props.link}> <p>View</p></Link>
            
        </animated.div>
    );
}

export default CardInfo;