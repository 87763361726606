import React from 'react'
import Content from '../components/Content'
import Hero from '../components/Hero'


class ArtPage extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        isActive: false
    }

    handleShow = () => {
        this.setState({
            isActive: true
        })
    }

    handleHide = () => {
        this.setState({
            isActive: false
        })
    }

    render() {
        return (
            <div>
                <Hero title={this.props.title} />
                <Content>
                    <p>In this page you'll find some redirections to places where I post some art.</p>
                    <p>As much as programming is my specialization, I like doodling and take a shot at conceptualizing parts of my stories' narrative.</p>
                    <p>I believe there is a certain charm to ideas that are shown directly from a developer or writer's hand. Be them doodles or elaborate drawings.</p>
                    <p>So, if you're curious about how I draw my characters, you can see that work <a href='https://www.artstation.com/team_azure_0men'>here</a>.</p>
                </Content>
            </div>
        );
    }

}



export default ArtPage