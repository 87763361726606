import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { Link, Router, Route } from 'react-router-dom'
import Nav from 'react-bootstrap/Nav'

import Card from '../components/Card'

//card images for carrousel
import placeholder_0 from '../assets/images/card_gameprojects2.png'
import placeholder_1 from '../assets/images/card_stories.png'
import placeholder_2 from '../assets/images/card_question.png'
import placeholder_3 from '../assets/images/card_art.png'


import BehindTheDevPage from '../pages/BehindTheDevPage';
import UnderConstructionPage from '../pages/UnderConstructionPage';

class Carrousel extends React.Component {

    constructor(props) {
        super(props);
        /*The things that the carrousel will have*/
        this.state = {
            items: [
                {
                    id: 0,
                    title: 'Here are the projects I\'ve made so far.',
                    subTitle: 'Game Projects',
                    imgSrc: placeholder_0,
                    link: 'games_page',
                    selected: false
                },
                {
                    id: 1,
                    title: 'Check out some of the stories I\'ve written for my games.',
                    subTitle: 'Story Samples',
                    imgSrc: placeholder_1,
                    link: 'story_hub',
                    selected: false
                },
                {
                    id: 2,
                    title: 'Even programmers like to doodle once in a while.',
                    subTitle: 'Art Samples',
                    imgSrc: placeholder_3,
                    link: 'art_page',
                    selected: false
                },
                {
                    id: 3,
                    title: 'Want to know what inspired me to take this route?',
                    subTitle: 'Behind the Dev',
                    imgSrc: placeholder_2,
                    link: 'behindthedev',
                    selected: false
                },
            ]
        }
    }
    

    /* Handles the card selection in the carrousel */
    handleCardClick = (id, card) => {
        let items = [...this.state.items];
        items[id].selected = items[id].selected ? false : true;
        items.forEach(item => {
            if (item.id !== id) {
                item.selected = false;
            }
        });

        this.setState(
            { items }
        );
    }

    /* Creates a Card component for each item */
    makeItems = (items) => {
        return items.map(item => {
            return <Card item={item} click={(e => this.handleCardClick(item.id, e))} key={item.id} />
        })
    }

    render() {
        return (
            <Container fluid={true}>
                <Row className="justify-content-around">
                    {this.makeItems(this.state.items)}
                </Row>
                <Route path="/behindthedev" exact render={() => <BehindTheDevPage title={this.state.behindthedev.title} />} />
                <Route path="/under_construction" exact render={() => <UnderConstructionPage title={this.state.under_construction.title} />} />
            </Container>
        );


    }
}

export default Carrousel;