import React from 'react'
import From from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import Hero from '../components/Hero'
import Content from '../components/Content'
import { Form } from 'react-bootstrap';
import Axios from 'axios'
import { Redirect } from 'react-router-dom'

class ContactPage extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
            disabled: false,
            emailSent: null
        }
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            disabled: true
        });

        Axios.post('/api/email', this.state)
            .then(res => {
                if(res.data.success){
                    this.setState({
                        disabled: false,
                        emailSent: true
                    })
                } else{
                    this.setState({
                        disabled: false,
                        emailSent: false
                    });
                }
            })
            .catch(err => {
                this.setState({
                    disabled: false,
                    emailSent: false
                });
            })
    }

    render(){
        return(
            <div>
                <Hero title = {this.props.title} />
                <Content>
                    <p>Want to exchange some words? </p>
                    <p>You can contact me at teamazureomen@gmail.com and say hi!</p>
                    {/* <Form onSubmit={this.handleSubmit}>
                        <Form.Group>
                            <From.Label htmlFor="full-name"> User Name </From.Label>
                            <Form.Control id="full-name" name="name" type="text" value={this.state.name} onChange={this.handleChange}/>
                        </Form.Group>

                        <Form.Group>
                            <From.Label htmlFor="email"> Email </From.Label>
                            <Form.Control id="email" name="email" type="email" value={this.state.email} onChange={this.handleChange}/>
                        </Form.Group>

                        <Form.Group>
                            <From.Label htmlFor="full-name"> Message </From.Label>
                            <Form.Control id="message" name="message" as="textarea" rows="3" value={this.state.message} onChange={this.handleChange}/>
                        </Form.Group>

                        <Button className="d-inline-block" variant="primary" type="submit" disabled={this.state.disabled}>
                            Send
                        </Button>

                        {this.state.email === true && <p className="d-inline success-msg">Message sent!</p>}
                        {this.state.email === false && <p className="d-inline err-msg">Message not sent.</p>}
                    </Form> */}
                </Content>
            </div>
        );
    }
    
}

export default ContactPage